<template>
  <div class="my-project-list">
    <div class="container-xl">
      <paginated-list :count="collection.count"
                      :total-count="collection.totalCount"
                      :data-loading="collection.loading"
                      @update="loadData"
      >
        <template v-slot:loading>
          <div class="card">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <div class="card">
            <div class="card-body">
              <p class="text-left">
                Вы зарегистрировались в качестве заемщика на Инвестиционной платформе Lendly.
                Теперь вы можете самостоятельно подать заявку нажав кнопку
                <router-link :to="{ name: 'myProjectRequests.create' }" class="text-nowrap">«новая заявка»</router-link>
                или нажмите кнопку
                <a @click.stop.prevent="callbackRequestModal" href="#" class="text-nowrap">«свяжитесь со мной»</a>.
                Наш менеджер перезвонит Вам и ответит на все вопросы, поможет заполнить заявку.
              </p>
            </div>
          </div>
        </template>
        <div v-for="item in collection.items"
             :class="{ 'border-danger': isLoanPayment(item.status) && item.nextPayment && item.nextPayment.overdue }"
             class="card mb-3"
        >
          <div class="card-body">
            <div v-if="isFundRaising(item.status)" class="row">
              <div class="col-12 col-sm-8 col-lg-5 order-0">
                <div class="h6">{{ item.name }}</div>
                <div class="small text-muted float-right"><date-format :date="item.maturityDate"/></div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="small text-success">{{ statusLabel(item.status) }}</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 order-2 order-sm-1 text-center">
                <div class="small text-muted">Ставка</div>
                <div>{{ item.interestRate }}%</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 order-3 order-sm-2 text-center">
                <div class="small text-muted">Срок</div>
                <div>{{ item.initialTerm }}&nbsp;мес.</div>
              </div>
              <div class="col-12 col-sm-8 col-lg-4 order-1 order-sm-3 my-3 mb-md-0 mt-lg-0">
                <div class="row">
                  <div class="col-6">
                    <div class="small text-muted">Набрано</div>
                    <div>
                      <money :money="item.investedSum"/>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="small text-muted">Цель</div>
                    <div>
                      <money :money="item.targetSum"/>
                    </div>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-success"
                       role="progressbar"
                       :style="{ width: (item.investedSum.amount / item.targetSum.amount * 100) + '%' }"
                       :aria-valuenow="Math.round(item.investedSum.amount / item.targetSum.amount * 100)"
                       aria-valuemin="0"
                       aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 offset-3 offset-sm-2 offset-lg-0 order-4 mt-sm-3 mt-lg-0 pt-sm-3 pt-lg-0 text-center">
                <router-link :to="{ name: 'myProject', params: { uuid: item.uuid } }" class="btn btn-outline-info btn-sm mt-2">
                  Детали
                </router-link>
              </div>
            </div>
            <div v-else-if="isFinished(item.status)" class="row">
              <div class="col-12 col-sm-8 col-lg-5">
                <div class="h6">{{ item.name }}</div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="small text-success">{{ statusLabel(item.status) }}</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 mt-3 mt-sm-0 text-center">
                <div class="small text-muted">Ставка</div>
                <div>{{ item.interestRate }}%</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 offset-6 offset-sm-0 offset-lg-5 mt-3 mt-sm-0 text-center">
                <router-link :to="{ name: 'myProject', params: { uuid: item.uuid } }" class="btn btn-outline-info btn-sm mt-2">
                  Детали
                </router-link>
              </div>
            </div>
            <div v-else-if="isLoanPayment(item.status)" class="row">
              <div class="col-12 col-sm-8 col-lg-5 order-0">
                <div class="h6">{{ item.name }}</div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="small text-info">{{ statusLabel(item.status) }}</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 order-3 order-sm-1 text-center">
                <div class="small text-muted">Ставка</div>
                <div>{{ item.interestRate }}%</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 order-4 order-sm-2 text-center">
                <div class="small text-muted">Месяц</div>
                <div>{{ item.nextPayment ? item.nextPayment.month : 0 }}/{{ item.initialTerm }}</div>
              </div>
              <div class="col-6 col-sm-3 col-lg-2 order-1 order-sm-3 my-3 mb-md-0 mt-lg-0 text-center">
                <div class="small text-muted">Следующий платеж</div>
                <div>
                  <money v-if="item.nextPayment" :money="calculateTotalPayment(item.nextPayment)"/>
                  <span v-if="!item.nextPayment">-</span>
                </div>
              </div>
              <div :class="{ 'text-danger': item.nextRepayment && item.nextRepayment.overdue }"
                   class="col-6 col-sm-3 col-lg-2 order-2 order-sm-4 my-3 mb-md-0 mt-lg-0 text-center">
                <div class="small text-muted">Дата сл. платежа</div>
                <date-format v-if="item.nextPayment" :date="item.nextPayment.date"/>
                <div v-if="item.nextPayment && item.nextPayment.overdue" class="small">Просрочка</div>
                <span v-if="!item.nextPayment">-</span>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 offset-3 offset-sm-4 offset-lg-0 order-5 mt-sm-3 mt-lg-0 text-center">
                <router-link :to="{ name: 'myProject', params: { uuid: item.uuid } }" class="btn btn-outline-info btn-sm mt-2">
                  Детали
                </router-link>
              </div>
            </div>
            <div v-else-if="isPaid(item.status)" class="row">
              <div class="col-12 col-sm-8 col-lg-5">
                <div class="h6">{{ item.name }}</div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="small text-info">{{ statusLabel(item.status) }}</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 mt-3 mt-sm-0 text-center">
                <div class="small text-muted">Ставка</div>
                <div>{{ item.interestRate }}%</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 offset-6 offset-sm-0 offset-lg-5 mt-3 mt-sm-0 text-center">
                <router-link :to="{ name: 'myProject', params: { uuid: item.uuid } }" class="btn btn-outline-info btn-sm mt-2">
                  Детали
                </router-link>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12 col-sm-8 col-lg-5">
                <div class="h6">{{ item.name }}</div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="small text-danger">{{ statusLabel(item.status) }}</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 mt-3 mt-sm-0 text-center">
                <div class="small text-muted">Ставка</div>
                <div>{{ item.interestRate }}%</div>
              </div>
              <div class="col-3 col-sm-2 col-lg-1 offset-6 offset-sm-0 offset-lg-5 mt-3 mt-sm-0 text-center">
                <router-link :to="{ name: 'myProject', params: { uuid: item.uuid } }" class="btn btn-outline-info btn-sm mt-2">
                  Детали
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
    <dialog-modal ref="dialog">
      <p v-if="callbackSent">
        Спасибо, наш менеджер свяжется с Вами в ближайшее время.
      </p>
      <p v-else>
        Обратиться к менеджеру платформы, который проконсультирует Вас и поможет заполнить заявку
      </p>
      <template v-slot:button-action>
        <button v-if="!callbackSent" @click="callbackRequest" type="button" class="btn btn-success">
          Связаться со мной
        </button>
      </template>
    </dialog-modal>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { DataCollection, DateFormat, DialogModal, Money, PaginatedList, Processing, projectStatusLabels } from "@/components/common";
import { Money as ApiMoney, ProjectStatus } from "@/utils/api-client";
import { createCallbackRequestManager, createProjectProvider } from "@/utils/di";

export default {
  name: "MyProjectList",
  components: {
    DateFormat,
    DialogModal,
    Money,
    PaginatedList,
    Processing
  },
  data() {
    return {
      collection: new DataCollection(),
      processing: false,
      callbackSent: false
    };
  },
  methods: {
    async loadData(page, perPage) {
      this.collection.startLoading();
      try {
        const provider = await createProjectProvider();
        const collection = await provider.getListOwned(page, perPage);
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    isFundRaising(status) {
      return status === ProjectStatus.FUND_RAISING;
    },
    isFinished(status) {
      return status === ProjectStatus.FINISHED;
    },
    isLoanPayment(status) {
      return status === ProjectStatus.LOAN_PAYMENT;
    },
    isPaid(status) {
      return status === ProjectStatus.PAID;
    },
    statusLabel(status) {
      return projectStatusLabels[status] || status;
    },
    calculateTotalPayment(payment) {
      let total = payment.principal.amount + payment.interest.amount;
      if (payment.penalty) {
        total += payment.penalty.amount;
      }
      return new ApiMoney(total, payment.principal.currencyCode);
    },
    callbackRequestModal() {
      this.$refs.dialog.open();
    },
    async callbackRequest() {
      this.processing = true;
      try {
        const manager = await createCallbackRequestManager();
        await manager.create();
        this.callbackSent = true;
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>

<style lang="scss">
.my-project-list {
  .dialog-modal {
    .modal-dialog {
      .modal-body {
        p {
          font-size: 1.2rem;
          text-align: center;
          margin-top: 2rem;
        }
      }
    }
  }
}
</style>
